/* eslint-disable vue/require-v-for-key */
<template>
  <a-modal :class="$style.container" v-model="isShow" title="关联订单" :footer="null">
    <a-form-model ref="form" :class="$style.form__ct" :model="form"  class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="订单号">
        <span v-for="(orderNo, index) in orderNos" :key="index">
           {{ index == orderNos.length-1 && orderNo || (orderNo +',')}}
        </span>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        id: ''
      },
      orderNos: [],
      disabledStatus: false,
      loading1: false
    }
  },
  created () {
  },
  computed: {},
  methods: {
    init (item) {
      this.form.id = item.id
      this.getOrderNoList(this.form.id)
    },
    // 订单编号列表
    async getOrderNoList (id) {
      const res = await this.$store.dispatch('http', {
        api: 'invoiceOrderNos',
        query: { invoiceId: id }
      })
      this.orderNos = res
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
