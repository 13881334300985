<template>
  <a-modal :class="$style.container" v-model="isShow" title="开票完成" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
     <a-form-model-item label="上传发票" prop="" style="margin-bottom:0">
        <div style="display:flex">
          <a-upload name="file"
                    list-type="picture-card"
                    class="avatar-uploader avatar-uploader1"
                    :show-upload-list="false"
                    :action="$consts.uploadFileUrl"
                    :headers="$store.state.header"
                    :before-upload="beforeUpload"
                    @change="handleChange($event)">
            <a-avatar shape="square" style="width:80px;height:80px" v-if="form.invoiceUrl" :src="form.invoiceUrl"/>
            <div v-else>
              <a-icon :type="loading1 ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </div>
      </a-form-model-item>
      <a-form-model-item label="填写发票地址" prop="invoiceUrl">
          <a-input v-model="form.invoiceUrl" placeholder="请输入" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
export default {
  inject: ['appRefs'],
  data () {
    return {
      invoiceId: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        invoiceUrl: '',
        status: 'complete'
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        invoiceUrl: [
          { required: true, message: '请上传或填写发票地址', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
  },
  computed: {
  },
  methods: {
    init (item) {
      this.invoiceId = item.id
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        invoiceUrl: '',
        status: ''
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf'
      if (!isJpgOrPng) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '图片格式不正确'
        })
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$store.dispatch('showToast', {
          type: 'error',
          message: '图片大小不能超过2MB！'
        })
      }
      return isJpgOrPng && isLt2M
    },
    async handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading1 = true
        return
      }
      if (info.file.status === 'done') {
        this.form.invoiceUrl = info.file.response.result
        this.loading1 = false
      }
    },
    // 提交保存.
    async determine () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'auditOrReject'
          let query = {
            invoiceId: this.invoiceId
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
