<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '驳回原因' : '驳回原因')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="id" v-show="false">
        <a-input v-model="form.id"/>
      </a-form-model-item>
      <a-form-model-item label="原因" prop="rejectMemo">
        <a-textarea v-model="form.rejectMemo" placeholder="请输入驳回原因"/>
      </a-form-model-item>
      <a-form-model-item label="" prop="" v-show="false">
        <a-input v-model="form.status"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>

export default {
  inject: ['appRefs'],
  data () {
    return {
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        id: '',
        rejectMemo: '',
        status: ''
      },
      disabledStatus: false,
      loading1: false,
      formRules: {
        rejectMemo: [{
          required: true,
          message: '原因不能为空',
          trigger: 'change'
        }]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
      this.form.id = item.id
      this.form.rejectMemo = item.rejectMemo
    },
    init2 (item) {
      this.form.status = item
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        id: undefined,
        rejectMemo: ''
      }
    },
    // 提交保存.
    async determine () {
      console.log('提交数据：', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let api = 'auditOrReject'
          let query = {
            invoiceId: this.form.id
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
