<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>

    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.invoiceNo" placeholder="申请编号"/>
      </a-form-item>
      <a-form-item>
        <a-select v-model="form.status" placeholder="状态" style="width:180px;">
          <a-select-option :value='item.code' v-for="(item, index) in statusList" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="((e)=>{queryData()})">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <!--    -->
    <a-table
      :columns="columns"
      :data-source="list"
      :rowKey='record=>record.id'
      :pagination="false"
      :scroll="{ x: 1800, y: 580 }"
      bordered
      >
      <span slot="action1" slot-scope="scope">
       <span style="color:#4f9efa;">{{ scope }}</span>
      </span>
       <span slot="action2" slot-scope="scope">
       <span style="color:red;">开票金额：{{ scope }}</span>
      </span>
      <span slot="action3" slot-scope="scope">
        <div>{{ scope | formatStatus }}</div>
      </span>
      <span slot="action4" slot-scope="scope">
        {{ scope === 1 ? '普票' : '专票' }}
      </span>
      <span slot="action5" slot-scope="scope">
        <div v-if="scope.nameType==='compan'">抬头信息：{{ scope | formatText }}</div>
        <div v-if="scope.nameType==='person'">抬头信息：{{ scope.name }}</div>
        <div v-if="scope.invoiceType===1">收件email：{{ scope | formatText3 }}</div>
        <div v-if="scope.invoiceType===2">收件地址：{{ scope | formatText2 }}</div>
      </span>
      <span slot="action6" slot-scope="scope">
        <div v-if="scope.invoiceType===2 && scope.status==='complete'">快递公司：{{ scope.expressName }}</div>
        <div v-if="scope.invoiceType===2 && scope.status==='complete'">运单号：{{ scope.expressNo }}</div>
      </span>
      <span slot="action" slot-scope="scope">
        <div style="align:center;" v-if="scope.status==='create'">
          <a @click="auditPass(scope)">审核通过</a>
          <a-divider type="vertical"/>
          <a @click="$refs.dialog.init($utils.clone(scope));$refs.dialog.isShow = true
            $refs.dialog.init2('reject'); ">驳回</a>
        </div>
        <div style="align:center;">
          <a v-if="scope.status==='audit' && scope.invoiceType===1"
            @click="$refs.dialog3.init($utils.clone(scope));$refs.dialog3.isShow = true">开票完成</a>
          <a v-if="scope.status==='audit' && scope.invoiceType===2"
            @click="$refs.dialog4.init($utils.clone(scope));$refs.dialog4.isShow = true">开票完成</a>
          <a-divider type="vertical" v-if="scope.status==='audit'"/>
          <a @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true">关联订单</a>
        </div>
        <div style="align:center;" v-if="scope.status==='complete' || scope.status==='cancel'">
          <a-popconfirm
                    title="确定要删除吗？"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="confirm(scope.id)"
                    @cancel="cancel"
                  >
            <a href="#">删除</a>
          </a-popconfirm>
        </div>
      </span>
    </a-table>
    <br/>
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <RejectInvoice ref="dialog" @save="queryData"></RejectInvoice>
    <OrderNOList ref="dialog2" @save="queryData"></OrderNOList>
    <NormalComplete ref="dialog3" @save="queryData"></NormalComplete>
    <SpecialComplete ref="dialog4" @save="queryData"></SpecialComplete>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import RejectInvoice from './dialog/reject.vue'
import OrderNOList from './dialog/orderNoList.vue'
import NormalComplete from './dialog/normalComplete.vue'
import SpecialComplete from './dialog/specialComplete.vue'

export default {
  components: {
    Breadcrumb,
    RejectInvoice,
    OrderNOList,
    NormalComplete,
    SpecialComplete
  },
  data () {
    return {
      visible: false,
      breadList: [
        {
          name: '发票管理',
          url: ''
        }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      isQueryLoading: false,
      statusList: [],
      form: {
        orderNo: '',
        invoiceNo: '',
        status: ''
      },
      form2: {
        status: ''
      },
      total: 0,
      pageSize: 10,
      current: 1,
      list: [],
      columns: [
        {
          title: '申请编号',
          dataIndex: 'invoiceNo',
          key: 'invoiceNo',
          width: 120,
          scopedSlots: { customRender: 'action1' }
        },
        {
          title: '费用',
          dataIndex: 'amount',
          key: 'amount',
          width: 120,
          scopedSlots: { customRender: 'action2' }
        },
        {
          title: '用户编号',
          dataIndex: 'userId',
          key: 'userId',
          width: 120
        },
        {
          title: '状态',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'action3' },
          width: 100
        },
        {
          title: '发票类型',
          dataIndex: 'invoiceType',
          key: 'invoiceType',
          scopedSlots: { customRender: 'action4' },
          width: 100
        },
        {
          title: '发票信息',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'action5' },
          width: 450
        },
        {
          title: '快递信息',
          dataIndex: '',
          key: '',
          scopedSlots: { customRender: 'action6' },
          width: 200
        },
        {
          title: '驳回原因',
          dataIndex: 'rejectMemo',
          key: 'rejectMemo',
          width: 190,
          ellipsis: true
        },
        {
          title: '申请时间',
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          width: 200
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
          key: 'operation',
          width: 200
        }
      ]
    }
  },
  created () {
    this.queryData()
    this.queryStatusList()
  },
  filters: {
    formatStatus (v) {
      if (v.status === 'create') {
        return '申请'
      } else if (v.status === 'audit') {
        return '开票中'
      } else if (v.status === 'complete') {
        return '开票完成'
      } else if (v.status === 'reject') {
        return '驳回'
      } else if (v.status === 'cancel') {
        return '已取消'
      }
    },
    formatText (v) {
      return v.name + ' ' + v.taxpayerNo + ' ' + v.tel + ' ' + v.bankName + ' ' + v.bankNo
    },
    formatText2 (v) {
      return v.addresseeName + ' ' + v.addresseeTel + ' ' + v.addresseeAddress
    },
    formatText3 (v) {
      return v.email + ' ' + v.invoiceUrl
    }
  },
  methods: {
    async queryStatusList () {
      const res = await this.$store.dispatch('http', {
        api: 'invoiceStatusList'
      })
      if (res) {
        const that = this
        for (var key in res) {
          that.statusList.push({ code: key, name: res[key] })
        }
      }
    },
    auditPass (scope) {
      this.$store.dispatch('showConfirm', {
        message: '确定审核通过？',
        yes: async _ => {
          await this.audit(scope)
        }
      })
    },
    async audit (scope) {
      this.form2.status = 'audit'
      const params = this.form2
      let api = 'auditOrReject'
      let query = {
        invoiceId: scope.id
      }
      let res = await this.$store.dispatch('http', {
        api,
        params,
        query,
        complete: () => {
        }
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '操作成功'
        })
        this.queryData()
      }
    },
    // 是否确认弹窗口  方法
    confirm (id) {
      this.del(id)
    },
    cancel (values) {
      console.log(values)
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.queryData()
    },
    // 重置表单
    resetForm () {
      this.form = {
        orderNo: '',
        invoiceNo: '',
        status: ''
      }
      this.queryData()
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'invoiceList',
        params: {
          ...this.form,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      this.list = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    async del (id) {
      const res = await this.$store.dispatch('http', {
        api: 'removeInvoice',
        query: {
          invoiceId: id
        }
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '删除成功'
        })
        this.queryData() // 传入数字 重置列表不会更改当前页
        // this.reloadList() // 不传数字 会刷新当前页为1  按时间排序 更新第一页会看到财编辑的页面
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
